import * as React from 'react';

import { FormState, FormActions } from 'src/components/popupFormExtended/dynamicFormObject/types';
import { TypeInvoiceCurrency } from './types';
import { DynamicFormObjectGlobalValidatorBaseProps, FieldsNotValid } from '../types';
import { PopupFormExtendedData } from 'src/data/popupFormTypes';
import { DynamicFormObjectHelperValidator } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/dynamicFormObjectHelperValidator/DynamicFormObjectHelperValidator';
import { validationData } from 'src/constants/popupContentDynamicExtended/validation';

const typeFnValidate = 'validateInvoiceCurrency';

/**
 * Validator to show warning when invoice currency is set different than bank account currency
 */
export class DynamicFormObjectInvoiceCurrencyValidator extends DynamicFormObjectHelperValidator {
    formState: FormState;
    fieldsNotValid: FieldsNotValid;
    dispatchFormState: React.Dispatch<FormActions>;
    popupFormExtendedData: PopupFormExtendedData;
    typeFnValidate: TypeInvoiceCurrency;
    constructor({
        formState,
        dispatchFormState,
        popupFormExtendedData,
        dataRef
    }: DynamicFormObjectGlobalValidatorBaseProps) {
        super({
            dispatchFormState,
            typeFnValidate,
            dataRef
        });
        this.formState = formState;
        this.fieldsNotValid = {};
        this.dispatchFormState = dispatchFormState;
        this.popupFormExtendedData = popupFormExtendedData;
        this.typeFnValidate = typeFnValidate;
    }

    async validate() {
        const bankAccountField = Object.values(this.formState.fields).find(
            (field) => field?.kod === 'numerrachunku'
        );
        if (!bankAccountField || bankAccountField.hidden) {
            return;
        }

        const invoiceCurrencyField = Object.values(this.formState.fields).find(
            (field) => field?.kod === 'waluta'
        );
        if (!invoiceCurrencyField || invoiceCurrencyField.hidden) {
            return;
        }

        if (invoiceCurrencyField.typ !== 'lista' && invoiceCurrencyField.typ !== 'lista_obca') {
            console.error(
                'Invoice currency field (waluta) is not a lista/lista_obc, cannot validate'
            );
            return;
        }
        const invoiceCurrency =
            invoiceCurrencyField.value.opcje[invoiceCurrencyField.value.value ?? ''];

        if (bankAccountField.typ !== 'lista' && bankAccountField.typ !== 'lista_obca') {
            console.error(
                'Bank account field (numerrachunku) is not a lista/lista_obca, cannot validate'
            );
            return;
        }
        // text for this field ends fith currency code
        const bankAccountCurrency = bankAccountField.value.opcje[bankAccountField.value.value ?? '']
            .split(' ')
            .slice(-1)[0];

        if (invoiceCurrency !== bankAccountCurrency) {
            this.setValidationResult({
                typeOfInfo: 'Warning',
                type: {
                    issue: this.typeFnValidate,
                    title: validationData[this.typeFnValidate].title,
                    message: validationData[this.typeFnValidate].message,
                    fieldCode: invoiceCurrencyField.kod,
                    id_zakladki: invoiceCurrencyField.id_zakladki
                }
            });
        }
    }
}
