import { BudgetOperationalState } from 'src/hooks/src/budget/useFormBudget';
import { BudgetScreenState } from 'src/store/src/budget/budget/types';
import { BudgetCalcCapfeeRemainingForMethod } from 'src/utils/src/budget/BudgetCalcCapfeeRemainingForMethod';
import { BudgetHandlerStateGlobalFnExecuteSharedProps } from 'src/utils/src/budget/BudgetHandlerStateGlobal';

type BudgetHandlerGlobalSummaryStructureProps = BudgetHandlerStateGlobalFnExecuteSharedProps & {};

export class BudgetHandlerGlobalSummaryStructure {
    budget: BudgetScreenState;
    operationalState: BudgetOperationalState;
    constructor(data: BudgetHandlerGlobalSummaryStructureProps) {
        this.budget = data.budget;
        this.operationalState = data.operationalState;
    }

    execute() {
        this.setStructruce();
    }

    private setStructruce() {
        this.budget.settlementMethods.forEach((method) => {
            let capfee = method?.capfee?.capfee ?? 0;
            if (method.rozliczaniecapfee && method.capfee) {
                capfee = new BudgetCalcCapfeeRemainingForMethod({
                    settledObjectsStructures: this.budget.settledObjectsStructures,
                    method
                }).execute();
            }

            this.operationalState.globalSummaryData[method.id] = {
                isLimit: method.rozliczanielimitgodzin,
                isCapfee: method.rozliczaniecapfee,
                object: {},
                limit: {},
                capfee
            };
        });
    }
}
