import { BudgetScreenState, SettledObjectsCodes } from 'src/store/src/budget/budget/types';
import { BudgetHandlerUtils } from '../BudgetHandlerUtils';
import {
    FieldCheckboxStateRHF,
    FieldDateStateRHF,
    FieldListStateRHF,
    FieldNumberStateRHF,
    FieldsStateUnionRHF,
    FieldTekstStateRHF,
    FieldTekstWithoutRestrictStateRHF
} from 'src/data/fieldsReactHookForm';
import { DateManager } from '../../shared/DateManager';
import { BudgetFormStateRHF } from 'src/hooks/src/budget/useFormBudget';

export class BudgetApiFormDataPreparer {
    budgetState: BudgetScreenState;
    dataState: BudgetFormStateRHF;

    constructor(budgetState: BudgetScreenState, dataState: BudgetFormStateRHF) {
        this.budgetState = budgetState;
        this.dataState = dataState;
    }

    prepareDataForFormData() {
        const data: { [key: string]: any } = {};
        let costExists = false;
        let cost: string | undefined = '';
        let objId: string | number | null = '';
        for (const methodId in this.dataState.main) {
            if (typeof this.dataState.main[methodId] === 'object') {
                for (const keyOfField in this.dataState.main[methodId]) {
                    if (!keyOfField.includes('#') && !keyOfField.includes('@')) {
                        const element = this.prepareDataForFormDataItem(
                            this.dataState.main[methodId][keyOfField],
                            keyOfField
                        );
                        if (
                            typeof element === 'object' &&
                            this.checkIfCanAppendFieldToApi(
                                this.dataState.main[methodId][keyOfField]
                            )
                        ) {
                            Object.assign(data, element);
                        }
                        if (keyOfField.includes('koszt_waluta_') && !costExists) {
                            objId = keyOfField.split('_')?.[2];
                            cost =
                                typeof element === 'object' ? element[keyOfField]?.toString() : '';
                        }
                        if (keyOfField.includes('koszt_invoice_') && !costExists) {
                            const isInvoiced =
                                typeof element === 'object' ? element[keyOfField] : 0;
                            const objId2 = keyOfField.split('_')?.[2];
                            if (objId === objId2 && isInvoiced === 1) {
                                costExists = true;
                            }
                        }
                    }
                }
            }
        }
        return { data, cost: costExists ? cost : '' };
    }

    private prepareDataForFormDataItem(field: FieldsStateUnionRHF, keyOfField: string) {
        switch (true) {
            case 'data' in field: {
                const oneObj: Required<FieldDateStateRHF> = field as Required<FieldDateStateRHF>;
                return {
                    [keyOfField]: DateManager.prepareDayMonthYearFromDate(oneObj.data)
                };
            }
            case 'tekst' in field: {
                const oneObj: Required<FieldTekstStateRHF> = field as Required<FieldTekstStateRHF>;
                return {
                    [keyOfField]: oneObj.tekst
                };
            }
            case 'checkbox' in field: {
                const oneObj: Required<FieldCheckboxStateRHF> =
                    field as Required<FieldCheckboxStateRHF>;
                return {
                    [keyOfField]: oneObj.checkbox ? 1 : 0
                };
            }
            case 'lista' in field: {
                const oneObj: Required<FieldListStateRHF> = field as Required<FieldListStateRHF>;
                return {
                    [keyOfField]: oneObj.lista.value
                };
            }
            case 'liczba' in field: {
                const oneObj: Required<FieldNumberStateRHF> =
                    field as Required<FieldNumberStateRHF>;
                return {
                    [keyOfField]: oneObj.liczba
                };
            }
            case 'tekst_bez_ograniczen' in field: {
                const oneObj: Required<FieldTekstWithoutRestrictStateRHF> =
                    field as Required<FieldTekstWithoutRestrictStateRHF>;
                return {
                    [keyOfField]: oneObj.tekst_bez_ograniczen
                };
            }
        }
    }

    private checkIfCanAppendFieldToApi(field: { [key: string]: any }) {
        if ('keyId' in field && typeof field.keyId === 'string') {
            const objectCode = field.keyId.split('#')[0];
            const fieldId = field.keyId.split('!')[1];
            const structureField = BudgetHandlerUtils.getField(
                objectCode as SettledObjectsCodes,
                fieldId,
                this.budgetState.settledObjectsStructures
            );
            if (structureField?.onlyText || structureField?.notSendToApi) {
                return false;
            }
        }
        return true;
    }
}
