import React, { FC } from 'react';

import { useTypedSelector } from 'src/store';
import { StartupAnimationProps } from './types';
import MecenasLogo from 'src/assets/logo/MECENASpion.svg';
import SyndykLogo from 'src/assets/logo/SYNDYKnapis.svg';
import styles from './styles.module.css';
import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';

export const StartupAnimation: FC<StartupAnimationProps> = () => {
    const systemName = useTypedSelector((state) => state.system.systemName);
    const startupLoadingImage = useTypedSelector((state) => state.system.startupLoadingImage);
    const width = useTypedSelector((state) => state.menu.menuWidth);

    return (
        <ThemeCompatibleIcon
            SvgComponent={
                startupLoadingImage
                    ? undefined
                    : systemName === 'Syndyk.iT'
                    ? SyndykLogo
                    : MecenasLogo
            }
            iconData={
                startupLoadingImage ? { svgFileName: startupLoadingImage, faCode: '' } : undefined
            }
            wrapperCommonProps={{ className: styles.wrapper }}
            sx={{ width: '100%', height: '100%' }}
        />
    );
};
