import { BudgetOperationalState } from 'src/hooks/src/budget/useFormBudget';
import {
    BudgetScreenState,
    BugdetMonthLevel,
    BugdetSettlementMethodLevel,
    MonthLevelType,
    MonthSubLevelItem,
    SettleObject,
    SettledObjectsCodes
} from 'src/store/src/budget/budget/types';
import { BudgetSetterStateField } from 'src/utils/src/budget/BudgetSetterStateField';
import { BudgetSetterStateGlobalProps } from 'src/utils/src/budget/BudgetSetterStateGlobal';
import { BudgetHandlerStateSum } from 'src/utils/src/budget/BudgetHandlerStateSum';
import { BudgetSetterStateLimit } from 'src/utils/src/budget/BudgetSetterStateLimit';
import { BudgetSetterObjectsToCalcLimitStake } from 'src/utils/src/budget/BudgetSetterObjectsToCalcLimitStake';
import { BudgetHandlerTemplateMonthLimit } from 'src/utils/src/budget/BudgetHandlerTemplateMonthLimit';
import { BudgetSetterOperationalState } from 'src/utils/src/budget/BudgetSetterOperationalState';
import { BudgetHandlerStateManageKeyMemo } from 'src/utils/src/budget/BudgetHandlerStateManageKeyMemo';
import { BudgetStableFieldSumType } from 'src/constants/budget/budgetStableFieldSum';
import { BudgetSetterStateSumBeforeYear } from 'src/utils/src/budget/BudgetSetterStateSumBeforeYear';

// Class working on only onMount

type BudgetSetterStateMethodProps = BudgetSetterStateGlobalProps & {
    operationalState: BudgetOperationalState;
};

export class BudgetSetterStateMethod extends BudgetSetterOperationalState {
    budget: BudgetScreenState;
    operationalState: BudgetOperationalState;
    budgetSetterStateField: BudgetSetterStateField;
    budgetHandlerStateSum: BudgetHandlerStateSum;
    budgetSetterStateLimit: BudgetSetterStateLimit;
    budgetSetterObjectsToCalcLimitStake: BudgetSetterObjectsToCalcLimitStake;
    budgetHandlerTemplateMonthLimit: BudgetHandlerTemplateMonthLimit;
    budgetHandlerStateManageKeyMemo: BudgetHandlerStateManageKeyMemo;
    budgetSetterStateSumBeforeYear: BudgetSetterStateSumBeforeYear;
    constructor({ budget, operationalState }: BudgetSetterStateMethodProps) {
        super({ budget, operationalState });
        this.budget = budget;
        this.operationalState = operationalState;
        this.budgetSetterStateField = new BudgetSetterStateField({ budget, operationalState });
        this.budgetHandlerStateSum = new BudgetHandlerStateSum({ budget, operationalState });
        this.budgetSetterStateLimit = new BudgetSetterStateLimit({ budget, operationalState });
        this.budgetSetterObjectsToCalcLimitStake = new BudgetSetterObjectsToCalcLimitStake({
            budget,
            operationalState
        });
        this.budgetHandlerTemplateMonthLimit = new BudgetHandlerTemplateMonthLimit({
            budget,
            operationalState
        });
        this.budgetHandlerStateManageKeyMemo = new BudgetHandlerStateManageKeyMemo({
            budget,
            operationalState
        });
        this.budgetSetterStateSumBeforeYear = new BudgetSetterStateSumBeforeYear({
            budget,
            operationalState
        });
    }

    protected runMethod(method: BugdetSettlementMethodLevel) {
        this.operationalState.method = method;
        this.runSumBeforeYear(method);

        if (method.rozliczanielimitgodzin) {
            this.runFields(method);
            this.runObjectsToCalcLimit(method);
            this.runLimit(method);
            this.runSum(method);
            this.runSetMemoKey(method);
        } else {
            this.runAll(method);
            this.runSetMemoKey(method);
        }
    }

    private runSumBeforeYear(method: BugdetSettlementMethodLevel) {
        this.budgetSetterStateSumBeforeYear.execute(method);
    }

    private runSetMemoKey(method: BugdetSettlementMethodLevel) {
        method.years.forEach((year) => {
            year?.months?.forEach((month) => {
                this.budgetHandlerStateManageKeyMemo.setKeyMonth({
                    monthId: month.id
                });
                [...month.cases, ...month.projects].forEach((caseItem) => {
                    caseItem.settledElements.forEach((settledElement) => {
                        settledElement.objects.forEach((object) => {
                            this.budgetHandlerStateManageKeyMemo.setKeyObject({
                                monthId: month.id,
                                code: settledElement.code,
                                objectId: object.id
                            });
                        });
                    });
                });
                month.settledElements?.forEach((settledElement) => {
                    settledElement.objects.forEach((object) => {
                        this.budgetHandlerStateManageKeyMemo.setKeyObject({
                            monthId: month.id,
                            code: settledElement.code,
                            objectId: object.id
                        });
                    });
                });
            });
        });
    }

    private runAll(method: BugdetSettlementMethodLevel) {
        method.years.forEach((year) => {
            year?.months?.forEach((month) => {
                this.runAllForMonthSublevel(method, month, month.cases, 'sprawy', 'sprawa');
                this.runAllForMonthSublevel(method, month, month.projects, 'projekty', 'projekt');

                month.settledElements?.forEach((settledElement) => {
                    settledElement.objects.forEach((object) => {
                        this.budgetSetterStateField.runSettleObject({
                            code: settledElement.code,
                            object,
                            method,
                            month,
                            methodLevelType: 'miesiac'
                        });
                    });
                });

                const settledSingleElementsArr: [SettledObjectsCodes, SettleObject][] =
                    Object.entries(month?.settledSingleElements ?? {}) as [
                        SettledObjectsCodes,
                        SettleObject
                    ][];
                settledSingleElementsArr.forEach(([code, object]) => {
                    this.budgetSetterStateField.runSettleObject({
                        code,
                        object,
                        method,
                        month,
                        methodLevelType: 'miesiac'
                    });
                });
                if (month.cases?.length) {
                    this.budgetHandlerStateSum.calcSumFromSum({
                        type: 'sprawy',
                        month,
                        method,
                        monthLevelType: 'sprawy',
                        methodLevelType: 'miesiac'
                    });
                }
                if (month.projects?.length) {
                    this.budgetHandlerStateSum.calcSumFromSum({
                        type: 'projekty',
                        month,
                        method,
                        monthLevelType: 'projekty',
                        methodLevelType: 'miesiac'
                    });
                }
                this.budgetHandlerStateSum.calcSumFromField({
                    type: 'miesiac',
                    month,
                    method,
                    methodLevelType: 'miesiac',
                    typeOfMatchedKeySum: 'miesiac'
                });
            });
        });
        method.settledElements?.forEach((settledElement) => {
            settledElement.objects.forEach((object) => {
                this.budgetSetterStateField.runSettleObject({
                    code: settledElement.code,
                    object,
                    method,
                    methodLevelType: settledElement.code
                });
            });
        });
        const settledSingleElementsArr: [SettledObjectsCodes, SettleObject][] = Object.entries(
            method.settledSingleElements ?? {}
        ) as [SettledObjectsCodes, SettleObject][];
        settledSingleElementsArr.forEach(([code, object]) => {
            this.budgetSetterStateField.runSettleObject({
                code,
                object,
                method,
                methodLevelType: code
            });
        });
        if (method.rozliczaniecapfee) {
            this.budgetHandlerStateSum.calcBeforeCapfee({
                type: 'przedcapfee',
                method,
                methodLevelType: 'przedcapfee'
            });
            this.budgetHandlerStateSum.calcCapFeeFromSum({
                type: 'capfee',
                method,
                methodLevelType: 'capfee'
            });
        }
        this.budgetHandlerStateSum.calcSummary({
            type: 'podsumowanie',
            method,
            methodLevelType: 'podsumowanie'
        });
    }

    private runAllForMonthSublevel(
        method: BugdetSettlementMethodLevel,
        month: BugdetMonthLevel,
        monthSubLevelItems: MonthSubLevelItem[],
        monthLevelType: 'sprawy' | 'projekty',
        stableFieldsSumType: BudgetStableFieldSumType
    ) {
        this.runForMonthSublevelForEveryObject(
            (x) => this.budgetSetterStateField.runSettleObject(x),
            method,
            month,
            monthSubLevelItems,
            monthLevelType
        );
        this.runForMonthSublevelForCaseItem(
            (x) => this.budgetHandlerStateSum.calcSumFromField(x),
            method,
            month,
            monthSubLevelItems,
            monthLevelType,
            stableFieldsSumType
        );
    }

    private runFields(method: BugdetSettlementMethodLevel) {
        method.years.forEach((year) => {
            year?.months?.forEach((month) => {
                this.runForMonthSublevelForEveryObject(
                    (x) => this.budgetSetterStateField.runSettleObject(x),
                    method,
                    month,
                    month.cases,
                    'sprawy'
                );
                this.runForMonthSublevelForEveryObject(
                    (x) => this.budgetSetterStateField.runSettleObject(x),
                    method,
                    month,
                    month.projects,
                    'projekty'
                );

                if (month.hoursLimit) {
                    this.budgetSetterStateField.runSettleObjectForHoursLimit({
                        code: 'limit_godzin',
                        method,
                        month,
                        object: month.hoursLimit
                    });
                }

                month.settledElements?.forEach((settledElement) => {
                    settledElement.objects.forEach((object) => {
                        this.budgetSetterStateField.runSettleObject({
                            code: settledElement.code,
                            object,
                            method,
                            month,
                            methodLevelType: 'miesiac'
                        });
                    });
                });

                const settledSingleElementsArr: [SettledObjectsCodes, SettleObject][] =
                    Object.entries(month?.settledSingleElements ?? {}) as [
                        SettledObjectsCodes,
                        SettleObject
                    ][];
                settledSingleElementsArr.forEach(([code, object]) => {
                    this.budgetSetterStateField.runSettleObject({
                        code,
                        object,
                        method,
                        month,
                        methodLevelType: 'miesiac'
                    });
                });
            });
        });
        method.settledElements?.forEach((settledElement) => {
            settledElement.objects.forEach((object) => {
                this.budgetSetterStateField.runSettleObject({
                    code: settledElement.code,
                    object,
                    method,
                    methodLevelType: settledElement.code
                });
            });
        });
        const settledSingleElementsArr: [SettledObjectsCodes, SettleObject][] = Object.entries(
            method.settledSingleElements ?? {}
        ) as [SettledObjectsCodes, SettleObject][];
        settledSingleElementsArr.forEach(([code, object]) => {
            this.budgetSetterStateField.runSettleObject({
                code,
                object,
                method,
                methodLevelType: code
            });
        });
    }

    private runObjectsToCalcLimit(method: BugdetSettlementMethodLevel) {
        method.years.forEach((year) => {
            year?.months?.forEach((month) => {
                this.runForMonthSublevelForEveryObject(
                    (x) => this.budgetSetterObjectsToCalcLimitStake.execute(x),
                    method,
                    month,
                    month.cases,
                    'sprawy'
                );
                this.runForMonthSublevelForEveryObject(
                    (x) => this.budgetSetterObjectsToCalcLimitStake.execute(x),
                    method,
                    month,
                    month.projects,
                    'projekty'
                );
            });
        });
        this.budgetSetterObjectsToCalcLimitStake.sortObjectsToCalcLimit();
    }

    private runLimit(method: BugdetSettlementMethodLevel) {
        method.years.forEach((year) => {
            year?.months?.forEach((month) => {
                this.budgetSetterStateLimit.runHoursLimit({
                    code: 'limit_godzin',
                    method,
                    month,
                    methodLevelType: 'miesiac'
                });
            });
        });
    }

    private runSum(method: BugdetSettlementMethodLevel) {
        method.years.forEach((year) => {
            year?.months?.forEach((month) => {
                this.runForMonthSublevelForCaseItem(
                    (x) => this.budgetHandlerStateSum.calcSumFromField(x),
                    method,
                    month,
                    month.cases,
                    'sprawy',
                    'sprawa',
                    (x) => this.budgetHandlerStateSum.calcSumFromSum(x)
                );
                this.runForMonthSublevelForCaseItem(
                    (x) => this.budgetHandlerStateSum.calcSumFromField(x),
                    method,
                    month,
                    month.projects,
                    'projekty',
                    'projekt',
                    (x) => this.budgetHandlerStateSum.calcSumFromSum(x)
                );
                this.budgetHandlerStateSum.calcSumMonthFromField({
                    type: 'miesiac',
                    month,
                    method,
                    methodLevelType: 'miesiac',
                    typeOfMatchedKeySum: 'miesiac'
                });
            });
        });
        if (method.rozliczaniecapfee) {
            this.budgetHandlerStateSum.calcBeforeCapfee({
                type: 'przedcapfee',
                method,
                methodLevelType: 'przedcapfee'
            });
            this.budgetHandlerStateSum.calcCapFeeFromSum({
                type: 'capfee',
                method,
                methodLevelType: 'capfee'
            });
        }
        this.budgetHandlerStateSum.calcSummary({
            type: 'podsumowanie',
            method,
            methodLevelType: 'podsumowanie'
        });
    }
}
