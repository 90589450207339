import { budgetStableFields } from 'src/constants/budget/budgetStableFields';
import { BudgetOperationalState } from 'src/hooks/src/budget/useFormBudget';
import {
    BudgetElementCommon,
    BugdetFieldStructure,
    BugdetFormFieldStructure,
    BugdetMonthLevel,
    BugdetSettlementMethodLevel,
    HoursLimitData,
    SettleObject,
    SettledObjectsCodes
} from 'src/store/src/budget/budget/types';
import { prefixKeySumSpecialStake } from 'src/utils/src/budget/BudgetHandlerStateSum';
import { BudgetSetterOperationalState } from 'src/utils/src/budget/BudgetSetterOperationalState';
import { BudgetSetterStateGlobalProps } from 'src/utils/src/budget/BudgetSetterStateGlobal';

import { calcTotalsInBudget } from 'src/utils/src/budget/calcTotalsInBudget';
import { checkIfFieldIsExcludedFromSum } from 'src/utils/src/budget/checkIfFieldIsExcludedFromSum';
import { createKeyFieldBudget } from 'src/utils/src/budget/createKeyFieldBudget';
import {
    CreateKeySumSharedProps,
    createKeySumBudget
} from 'src/utils/src/budget/createKeySumBudget';
import { setInitValueInBudget } from 'src/utils/src/budget/setInitValueInBudget';
import { HelperRHF } from 'src/utils/src/shared/HelperRHF';

// Class working on only onMount

type PrepareDefaultValueFnProps = CreateKeySumSharedProps & {
    field: BugdetFieldStructure;
    object: SettleObject;
    code: SettledObjectsCodes;
    method: BugdetSettlementMethodLevel;
    month?: BugdetMonthLevel;
};

type RunSettleObjectFnProps<T extends BudgetElementCommon> = CreateKeySumSharedProps & {
    object: T;
    method: BugdetSettlementMethodLevel;
    code: SettledObjectsCodes;
    month?: BugdetMonthLevel;
};

type BudgetSetterStateFieldProps = BudgetSetterStateGlobalProps & {
    operationalState: BudgetOperationalState;
};
export class BudgetSetterStateField extends BudgetSetterOperationalState {
    constructor({ budget, operationalState }: BudgetSetterStateFieldProps) {
        super({ budget, operationalState });
    }

    runSettleObject(data: RunSettleObjectFnProps<SettleObject>) {
        const { object, code } = data;
        this.setToOSStructure(code);
        this.setToOSFieldsStructure({
            settled: object.settled,
            code,
            settledInThisSettlingObject: object.settled && object.settledInThisSettlingObject
        });
        this.operationalState.fieldsStructure.forEach((field) => {
            this.prepareDefaultValue({
                ...data,
                field
            });
        });
    }

    runSettleObjectForHoursLimit(data: RunSettleObjectFnProps<HoursLimitData>) {
        const { object, code, method, month } = data;
        this.setToOSStructure(code);
        this.setToOSFieldsStructure({
            settled: object.settled,
            code,
            settledInThisSettlingObject: object.settled && object.settledInThisSettlingObject
        });

        // create invoice field
        const field = budgetStableFields['limit_godzin'];
        const { keyField } = createKeyFieldBudget({
            name: field.name,
            objectId: object.id,
            monthDate: month?.data_rozp,
            methodId: method.id
        });
        const { keySum } = createKeySumBudget({
            ...data,
            fieldId: field.id,
            methodId: method.id,
            monthId: month?.id,
            code
        });
        this.operationalState.fieldsTemp[keyField] = {
            ...setInitValueInBudget({
                values: {
                    settled: object.settled,
                    settledInThisSettlingObject:
                        object.settled && object.settledInThisSettlingObject
                },
                bugdetFormVisibleFieldStructure: field,
                employees: this.budget.employees
            }),
            keyReset: 1,
            keyId: keySum,
            isExcludedFromSum: checkIfFieldIsExcludedFromSum(code, field.id)
        };
    }

    private prepareDefaultValue(data: PrepareDefaultValueFnProps) {
        const { field, object, code, method, month } = data;

        if (!field?.onlyText && !field.name?.includes('fake')) {
            const { keyField } = createKeyFieldBudget({
                name: field?.name,
                objectId: object.id,
                monthDate: month?.data_rozp,
                methodId: method.id
            });
            if (keyField && method?.id) {
                const { keySum } = createKeySumBudget({
                    ...data,
                    fieldId: field.id,
                    methodId: method.id,
                    monthId: month?.id,
                    code
                });
                if (
                    field.column === 'cena brutto {waluta}' ||
                    field.column === 'cena netto {waluta}'
                ) {
                    this.operationalState.fieldsTemp[keyField] = {
                        ...calcTotalsInBudget({
                            fields: this.operationalState.fieldsStructure,
                            values: object.values,
                            bugdetFormVisibleFieldStructure: field,
                            code
                        }),
                        keyReset: 1,
                        keyId: keySum
                    };
                } else {
                    this.operationalState.fieldsTemp[keyField] = {
                        ...setInitValueInBudget({
                            values: {
                                ...object.values,
                                settled: object.settled,
                                settledInThisSettlingObject:
                                    object.settled && object.settledInThisSettlingObject
                            },
                            bugdetFormVisibleFieldStructure: field,
                            employees: this.budget.employees
                        }),
                        keyReset: 1,
                        keyId: keySum,
                        isExcludedFromSum: checkIfFieldIsExcludedFromSum(code, field.id)
                    };
                    this.setSpecialRateOption(field, object, keySum);
                }
            }
        }
    }

    private setSpecialRateOption(
        field: BugdetFormFieldStructure,
        object: SettleObject,
        keySum: string
    ) {
        if (field.specialRateOptionCode) {
            const rest = keySum.split('#')?.[1];
            const keySpecialStake = `${prefixKeySumSpecialStake}#` + rest;
            const createKeySpecialStake = HelperRHF.createKeySpecialStake({
                specialRateOptionCode: field.specialRateOptionCode,
                objectId: object.id
            });
            const specialState = object.values['stawkaspecjalna'] === 't';
            this.operationalState.fieldsTemp[createKeySpecialStake] = {
                checkbox: specialState,
                keyReset: 1,
                keyId: keySpecialStake
            };
        }
    }
}
