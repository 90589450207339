export const SYSTEM_NAMES = ['Mecenas.iT', 'Syndyk.iT', 'eRAPORT'] as const;

export const DEFAULT_SYSTEM_COLOR = '#750B22' as const;

export { NOT_LOGGED_USER_DATA } from './NotLoggedUserData';

export { phpScreenToComponentUrl } from './phpScreenToComponentUrl';

export { default as STATIC_SEARCH_FIELDS } from './staticSearchFields';

export const NAVIGATION_START_POINT_SET_KEY = 'MecenasNavigationStartPointSet';
export const NAV_DO_NOT_CLEAR_BACK_LEVEL = 'MecenasNavigationDoNotClearBackLevel';
export const NAV_BACK_LEVEL = 'MecenasNavigationBackLevel';

export const AUTO_LOGOUT_EVENT_NAME = 'inactivity_auto_logout';

export const ADD_BACK_BROADCAST_CHANNEL = 'AddBackBroadcastChannelMecenasIt';

// inputs

export const ADDRESS_OPTIONS_KEY = 'MecenasAddressCountriesVoivodshipsOptions';
export const GUS_SESSION_SESSIONSTORAGE_KEY = 'MecenasGUSSession';

// menu stuff

export const MENU_HEADER_HEIGHT = '50px';
export const MENU_LEFT_NAVIGATION_WIDTH_UNFOLDED = '194px';
export const MENU_LEFT_NAVIGATION_WIDTH_FOLDED = '100px';

// callendar stuff
export const DAYS_OF_WEEK = ['Nd', 'Pn', 'Wt', 'Śr', 'Czw', 'Pt', 'Sb'] as const;
export const MONTH_NAMES = [
    'Styczeń',
    'Luty',
    'Marzec',
    'Kwiecień',
    'Maj',
    'Czerwiec',
    'Lipiec',
    'Sierpień',
    'Wrzesień',
    'Październik',
    'Listopad',
    'Grudzień'
] as const;
export const MONTH_SHORT_NAMES = [
    'Sty',
    'Lut',
    'Mar',
    'Kwi',
    'Maj',
    'Cze',
    'Lip',
    'Sie',
    'Wrz',
    'Paź',
    'Lis',
    'Gru'
] as const;

export const ICON_TYPES = [
    'Prev',
    'Next',
    'Back',
    'Refresh',
    'Month',
    'Week',
    'People',
    'Help',
    'More',
    'Open',
    'WorkWeek',
    'Today',
    'Event',
    'Task',
    'Fold',
    'Unfold',
    'Add',
    'Close',
    'Check'
] as const;

// list screen stuff

export const INITIAL_OBJECTS_PER_PAGE = 10;

export const API_UNLOGGED_USER_ERROR_CODE = 100;
